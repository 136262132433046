import store from '@/store/index.js';
import { createRouter, createWebHistory } from 'vue-router'

import RentHistory from '@/views/dongnanyaView/HistoryRent.vue';
import RentAcive from '@/views/dongnanyaView/ActiveRent.vue';
import PermanentRights from '@/views/dongnanyaView/YongjiuQuanyiList.vue';
import CdkUse from '@/views/dongnanyaView/Cdk.vue';
import EmailRegister from '@/views/dongnanyaView/RegisterEmail.vue';
import Login from '@/views/dongnanyaView/Login.vue';

const routes = [
    // 东南亚相关业务投流测试
    { path: '/', component: CdkUse},
    { path: '/dny/rent_history', component: RentHistory, meta: { requiresAuth: true } },
    { path: '/dny/rent_active', component: RentAcive, meta: { requiresAuth: true } },
    { path: '/dny/yongjiuquanyi', component: PermanentRights, meta: { requiresAuth: true } },
    { path: '/dny/cdk_use', component: CdkUse},
    { path: '/register', meta: { isShowLayout: true }, component: EmailRegister },
    { path: '/login', meta: { isShowLayout: true }, component: Login },
    // { path: '/profile', component: UserProfile, meta: { requiresAuth: true } },
    // { path: '/profile', component: UserProfile, },
    // { path: '/product/:id', component: ProductDetail }, // 新增
    // {
    //     path: '/',
    //     name: 'home',
    //     component: HomeView
    // },
    // { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },
    // {
    //     path: "/ecommerce/products",
    //     name: "products",
    //     meta: { title: "Products", authRequired: true },
    //     component: () =>
    //         import ("../views/ecommerce/products"),
    // },
    // { path: '/cdk', component: CDK },

    // 东南亚相关业务投流测试
    // { path: '/dny/rent_history', component: RentHistory, meta: { requiresAuth: true } },
    // { path: '/dny/rent_active', component: RentAcive, meta: { requiresAuth: true } },
    // { path: '/dny/yongjiuquanyi', component: PermanentRights, meta: { requiresAuth: true } },
    // { path: '/dny/cdk_use', component: CdkUse},
];


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
      // 始终滚动到顶部
      return { top: 0 }
    },
})

// 导航守卫
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        const token = localStorage.getItem('access_token');
        if (!token) {
            next({
                path: '/login',
                query: { redirect: to.fullPath }
            });
        } else {
            next();
        }
    } else {
        next();
    }
    // 头部导航切换
    store.commit('SET_SELECTEDKEYS', [to?.path || '']);
});

export default router